import React from 'react'
import { setDefaultErrorBoundaryFallback } from 'react-app-error-boundary'

import AppBase, { DefaultErrorBoundaryFallback } from 'src/AppBase'
import { AppSettings } from 'src/AppSettingsContext'
import { ThemeProvider } from 'src/ThemeProvider'

import Router from './Router'
import {
  GDPRConsentProvider,
  RiskConsentProvider,
  TokenError,
} from './components'
import { checkInvestorSession } from './features/User'
import { history, routes } from './routes'
import { store } from './store'

setDefaultErrorBoundaryFallback(DefaultErrorBoundaryFallback)

const App: React.FC = () => {
  return (
    <ThemeProvider theme="polity">
      <AppSettings routes={routes}>
        <AppBase
          history={history}
          store={store}
          login={checkInvestorSession}
          allowGuest
        >
          <RiskConsentProvider>
            <GDPRConsentProvider>
              <TokenError />
              <Router />
            </GDPRConsentProvider>
          </RiskConsentProvider>
        </AppBase>
      </AppSettings>
    </ThemeProvider>
  )
}

export default App
