import { useCallback, useRef } from 'react'

import { useImmer } from 'use-immer'

import { useDispatch } from 'src/apps/auth'
import { useAsync, useAsyncUnwrap } from 'src/hooks'

import { InvestorApi } from '../../api'
import { inviteAdvisor } from '../../thunks'

import {
  IUseWizardParams,
  IWizardResult,
  IWizardState,
  WizardStep,
} from './types'

// ---

export const DEFAULT_STEPS_ORDER: WizardStep[] = [
  WizardStep.Intro,
  WizardStep.Avatar,
  WizardStep.Advisor,
]

const SHORTENED_STEPS_ORDER: WizardStep[] = [
  WizardStep.Avatar,
  WizardStep.Advisor,
]

export function getStepsOrder(skipIntro?: boolean): WizardStep[] {
  return skipIntro ? SHORTENED_STEPS_ORDER : DEFAULT_STEPS_ORDER
}

// ---

export function useWizard(params: IUseWizardParams) {
  const { step: stepRaw, onStepChange, onComplete, skipIntro = false } = params

  const STEPS_ORDER = getStepsOrder(skipIntro)

  const { step, getStepIndex, getNextStep, setStepDone } = useStepManager({
    current: stepRaw,
    order: STEPS_ORDER,
  })

  const [state, setState] = useImmer<IWizardState>({})

  // Put state into ref so it's immediately available in callback on update
  const refState = useRef(state)
  refState.current = state

  const [submitState, onWizardSubmit] = useAsyncUnwrap(
    useAsync(useWizardSubmitHandler())
  )

  const onBack = () => onStepChange?.(getNextStep(step, false))
  const onNext = async () => {
    setStepDone(step, true)

    const isComplete = step === STEPS_ORDER[STEPS_ORDER.length - 1]
    if (!isComplete) {
      onStepChange?.(getNextStep(step, true))
    } else {
      const { stepAvatar, stepAdvisor } = refState.current
      if (stepAvatar !== undefined && stepAdvisor !== undefined) {
        const result = await onWizardSubmit({ stepAvatar, stepAdvisor })
        onComplete?.(result)
      }
    }
  }

  return {
    step,
    stepIdx: getStepIndex(step),
    state,
    setState,
    onBack,
    onNext,
    submitState: {
      loading: submitState.status === 'loading',
      error: submitState.error,
    },
  }
}

function useWizardSubmitHandler() {
  const [createAvatar] = InvestorApi.endpoints.createAvatar.useMutation()
  const dispatch = useDispatch()

  return async (
    state: Pick<Required<IWizardState>, 'stepAvatar' | 'stepAdvisor'>
  ): Promise<IWizardResult> => {
    const avatar = await createAvatar(state.stepAvatar).unwrap()
    const { advisor } = state.stepAdvisor
    const actionInvite = inviteAdvisor({ avatar, advisor })
    const { room_id, room_link } = await dispatch(actionInvite).unwrap()
    return { room_id, room_link, avatar, advisor }
  }
}

// ---

function useStepManager(params: {
  current: WizardStep | undefined
  order: WizardStep[]
}) {
  const { order: STEPS_ORDER, current: step } = params

  const refOrder = useRef(STEPS_ORDER)
  refOrder.current = STEPS_ORDER

  const getStepIndex = useCallback(
    (step: WizardStep) => refOrder.current.indexOf(step),
    []
  )

  const getNextStep = useCallback(
    (step: WizardStep, forward: boolean) => {
      const order = refOrder.current
      const total = order.length
      const idx = getStepIndex(step)
      const next = forward ? Math.min(idx + 1, total - 1) : Math.max(idx - 1, 0)
      return order[next]
    },
    [getStepIndex]
  )

  const [passedSteps, updatePassedSteps] = useImmer(() => new Set<number>())

  const setStepDone = useCallback(
    (step: WizardStep, completed: boolean) => {
      updatePassedSteps(set => {
        const idx = getStepIndex(step)
        if (completed) {
          set.add(idx)
        } else {
          set.delete(idx)
        }
      })
    },
    [updatePassedSteps, getStepIndex]
  )

  function normalizeStep(step: WizardStep | undefined): WizardStep {
    const defaultStep = STEPS_ORDER[0]
    const total = STEPS_ORDER.length

    if (step === undefined) return defaultStep

    const idx = getStepIndex(step)
    if (
      idx > 0 &&
      idx <= total - 1 &&
      (passedSteps.has(idx - 1) || isDebugMode())
    )
      return step

    return defaultStep
  }

  return {
    step: normalizeStep(step),
    setStepDone,
    getStepIndex,
    getNextStep,
  }
}

// A handy helper for development.
// Maybe should put it somewhere to top-level utils module.
function isDebugMode() {
  return (
    process.env.NODE_ENV !== 'production' &&
    new URLSearchParams(window.location.search).has('debug')
  )
}
