import { toast } from 'react-toastify'

import clsx from 'clsx'

import { useDispatch } from 'src/apps/auth'
import { Loader, UserPhoto } from 'src/components'
import { useAsync } from 'src/hooks'

import { loginAvatar } from '../../thunks'

import { AvatarConnectionStatus } from './ConnectionStatus'
import { IAvatarItemProps } from './types'

import styles from './AvatarItem.module.scss'

export function AvatarItem(props: IAvatarItemProps) {
  const { name, id, photo, inactive = false, className } = props

  const [loginState, login] = useAsync(useLoginHandler(id))
  return (
    <li
      className={clsx(styles.item, className, {
        [styles.inactive]: inactive,
      })}
      onClick={inactive ? undefined : login}
    >
      <div className={styles.photo_container}>
        {photo === undefined ? (
          <span style={{ fontSize: '1.5rem' }}>?</span>
        ) : (
          <UserPhoto src={photo} alt="avatar-icon" size={64} />
        )}

        <If condition={loginState.status === 'loading'}>
          <Loader size={56} width={7} className={styles.loader} />
        </If>
      </div>

      <div className={styles.content}>
        <h3 className={styles.name} title={name}>
          {name}
        </h3>

        <AvatarConnectionStatus pending={inactive} />
      </div>
    </li>
  )
}

function useLoginHandler(avatarId: IAvatarItemProps['id']) {
  const dispatch = useDispatch()
  return () =>
    dispatch(loginAvatar(avatarId))
      .unwrap()
      .catch(e => {
        toast.error(e.message, {
          toastId: 'avatar-login',
          autoClose: false,
        })
      })
}
