import { push } from 'connected-react-router'
import { useCallback } from 'react'

import qs from 'qs'

import { routes, useDispatch } from 'src/apps/auth'
import { useSyncQueryParams } from 'src/hooks'

import { IWizardProps, NewAvatarWizard, WizardStep } from '../../components'
import { IAvatarCreatedPageQueryParams } from '../AvatarCreated'

export function WizardContainer(
  props: Pick<IWizardProps, 'skipIntro' | 'showProgressbar'>
) {
  const [step, setStep] = useStep()
  const dispatch = useDispatch()

  return (
    <NewAvatarWizard
      {...props}
      step={step}
      onStepChange={setStep}
      // Sync query param with component's internal steps validation logic
      onInitialized={step => setStep(step, { replace: true })}
      onComplete={data => {
        const queryParams: IAvatarCreatedPageQueryParams = {
          name: data.advisor.name,
          img: data.advisor.photo ?? undefined,
          room: data.room_id,
        }
        const query = qs.stringify(queryParams)
        dispatch(push(`${routes.investor.avatar_created}?${query}`))
      }}
    />
  )
}

function useStep() {
  type IWizardQuery = {
    step: WizardStep
  }
  const [{ step }, setParams] = useSyncQueryParams<IWizardQuery>()

  const setStep = useCallback(
    (step: IWizardQuery['step'], opts?: Parameters<typeof setParams>[1]) => {
      setParams({ step }, opts)
    },
    [setParams]
  )

  return [step, setStep] as const
}
