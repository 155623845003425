import { createSlice } from '@reduxjs/toolkit'

import { IInvestorState } from './types'

const initialState: IInvestorState = {
  loading: false,
  error: undefined,
}

export const InvestorSlice = createSlice({
  name: 'investor',
  initialState,

  reducers: {},
})
