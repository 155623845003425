// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-7-1!../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-7-3!../../../../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-7-4!./Cards/Card.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RoleSelect_cards_list__1I5El {\n  /* grey out everything but the loading item.\n  * Like \"pay attention only to the loading one\" */\n}\n.RoleSelect_cards_list__1I5El:has(." + ___CSS_LOADER_ICSS_IMPORT_0___.locals["loading"] + ") ." + ___CSS_LOADER_ICSS_IMPORT_0___.locals["card"] + ":not(." + ___CSS_LOADER_ICSS_IMPORT_0___.locals["loading"] + ") {\n  filter: grayscale(1) blur(1px);\n}\n.RoleSelect_cards_list__1I5El ." + ___CSS_LOADER_ICSS_IMPORT_0___.locals["card"] + ":hover {\n  background-color: var(--th-clr-bg-selected);\n}", "",{"version":3,"sources":["webpack://src/apps/auth/pages/Login/components/RoleSelect.module.scss"],"names":[],"mappings":"AAEA;EACE;kDAAA;AACF;AAEI;EACE,8BAAA;AAAN;AAIE;EACE,2CAAA;AAFJ","sourcesContent":["@value loading, card from \"./Cards/Card.module.scss\";\n\n.cards_list {\n  /* grey out everything but the loading item.\n  * Like \"pay attention only to the loading one\" */\n  &:has(.loading) {\n    .card:not(.loading) {\n      filter: grayscale(1) blur(1px);\n    }\n  }\n\n  .card:hover {\n    background-color: var(--th-clr-bg-selected);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["loading"] + "",
	"card": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["card"] + "",
	"cards_list": "RoleSelect_cards_list__1I5El"
};
export default ___CSS_LOADER_EXPORT___;
