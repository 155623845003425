import React, { useState } from 'react'
import { BsPersonPlus } from 'react-icons/bs'

import { Button, Portal, useModal } from 'src/components'

import {
  IWizardResult,
  NewAvatarWizard,
  WizardStep,
  WizardStepLayoutComponent,
  WizardSuccessScreen,
} from '../../components'

import styles from './styles.module.scss'

export function BtnAddAvatar() {
  const modal = useNewAvatarWizard()
  return (
    <Button className={styles.button} onClick={modal.open} variant="secondary">
      <BsPersonPlus size={20} /> Create Avatar
      {modal.$element}
    </Button>
  )
}

const MODAL_SLOTS = {
  title: 'avatar-modal-title',
  subtitle: 'avatar-modal-desc',
  footer: 'avatar-modal-footer',
}

const StepLayout: WizardStepLayoutComponent = props => {
  const { title, subtitle, footer, children } = props
  return (
    <>
      <Portal node={MODAL_SLOTS.title}>{title}</Portal>
      <Portal node={MODAL_SLOTS.subtitle}>{subtitle}</Portal>
      <Portal node={MODAL_SLOTS.footer}>{footer}</Portal>
      {children}
    </>
  )
}

function useNewAvatarWizard() {
  const [step, setStep] = useState<WizardStep>()
  const [result, setResult] = useState<
    Pick<IWizardResult, 'room_id' | 'advisor'> | undefined
  >()

  return useModal(
    {
      // prevent accidental data loss on close
      shouldCloseOnOverlayClick: false,

      onAfterClose() {
        setStep(undefined)
        setResult(undefined)
      },

      idSlotTitle: MODAL_SLOTS.title,
      idSlotSubtitle: MODAL_SLOTS.subtitle,
      idSlotFooter: MODAL_SLOTS.footer,

      style: {
        content: { width: 592 },
      },
    },
    ({ close }) => (
      <Choose>
        <When condition={result === undefined}>
          <NewAvatarWizard
            skipIntro
            showProgressbar={false}
            mode="compact"
            step={step}
            onStepChange={setStep}
            onInitialized={setStep}
            StepLayoutComponent={StepLayout}
            onComplete={setResult}
          />
        </When>

        <Otherwise>
          <WizardSuccessScreen
            mode="compact"
            roomId={(result as NonNullable<typeof result>).room_id}
            advisorName={(result as NonNullable<typeof result>).advisor.name}
            advisorImg={(result as NonNullable<typeof result>).advisor.photo}
            onOpenElement={close}
            onGoToDashboard={close}
            render={({ img, header, footer }) => (
              <>
                {img}
                {header}
                <Portal node={MODAL_SLOTS.footer}>{footer}</Portal>
              </>
            )}
          />
        </Otherwise>
      </Choose>
    )
  )
}
