import React, { useState } from 'react'

import { useModal } from 'src/components'
import { IJWTPair, UserRole } from 'src/types'

import { VerificationForm } from '../../components'
import { UserAPI } from '../../features/User'

import { isRoleNeedVerification, useLoginHandler } from './lib'

export function useRoleSelectFlow(tokens: IJWTPair) {
  const [loginState, handleLogin] = useLoginHandler(tokens)
  const isLoggingIn = loginState.status === 'loading'
  const [selectedRole, setSelectedRole] = useState<UserRole>()

  const verificator = useVerificationModal({
    onVerified() {
      if (selectedRole !== undefined) {
        return handleLogin(selectedRole).catch(e => {
          console.error('Error authenticating verified user\n', e)
        })
      }
    },
    onClosed() {
      /* If by the time form closed login is not in process,
       * it means `onVerified` cb hasn't been called.
       * In that case cancel selection, like "action failed". */
      if (!isLoggingIn) {
        setSelectedRole(undefined)
      }
    },
  })

  const onSelectRole = async (role: UserRole) => {
    if (isLoggingIn) return

    setSelectedRole(role)

    if (isRoleNeedVerification(role)) {
      verificator.open()
    } else {
      try {
        await handleLogin(role)
      } catch (e) {
        setSelectedRole(undefined)
      }
    }
  }

  return {
    onSelectRole,
    isLoggingIn,
    selectedRole,
    $verificator: verificator.$element,
  }
}

function useVerificationModal(
  opts: {
    onVerified?(): void
    onClosed?(): void
  } = {}
) {
  const { onVerified, onClosed } = opts
  return useModal(
    {
      onAfterClose: onClosed,
      title: 'Code Verification',
      subtitle: 'Please enter security code you received in invitation email',
    },
    ({ close }) => (
      <VerificationForm
        onSubmit={async code => {
          await UserAPI.verifySecurityCode(code)
          await onVerified?.()
          close()
        }}
      />
    )
  )
}
