import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { AUTH_PORTAL_ERROR_QUERY_PARAM, ERROR_REFRESH_TOKEN } from 'src/const'
import { useQueryParams } from 'src/hooks'

export function TokenError() {
  const { error } = useQueryParams<{
    [AUTH_PORTAL_ERROR_QUERY_PARAM]?: string
  }>()

  useEffect(() => {
    if (error === ERROR_REFRESH_TOKEN) {
      toast.error(
        'Sorry, we have failed to restore your session. Please try to log in again.',
        {
          autoClose: false,
        }
      )
    }
  }, [error])

  return null
}
