import { Alert, Badge, Flex, Icon, Link, Page } from 'src/components'

import { fractalAuthUrl } from '../../config/fractal'

import artboardImg from './img/artboard.png'
import complianceImg from './img/compliance.png'
import humanImg from './img/human.png'
import logoImg from './img/logo.png'

import styles from './FractalInfo.module.scss'

export function FractalInfoPage() {
  return (
    <div className={styles.root}>
      <Page
        hero={<img className={styles.logo} src={logoImg} alt="Fractal Info" />}
        title={<div className={styles.header}>Whitelisting with Fractal</div>}
        description={
          <div className={styles.header}>
            Fractal empower users by crafting open-source, composable identity
            solutions that seamlessly integrates with Polity
          </div>
        }
        alignHeader="center"
        alignContent="center"
      >
        <div className={styles.content}>
          <div className={styles.buttons}>
            <Link
              external
              endIcon={null}
              to={fractalAuthUrl}
              variant="btn-primary"
            >
              Continue to Fractal
            </Link>
          </div>
          <div className={styles.cards}>
            <Card
              step="1"
              img={artboardImg}
              title="Sign up with Fractal"
              description="Set up your account instantly using your email address."
            />
            <Card
              step="2"
              img={humanImg}
              title="Verify your identity"
              description="Get approved quickly through a secure and seamless process."
            />
            <Card
              step="3"
              img={complianceImg}
              title="Get access to Polity"
              description="Get instant access to Polity when your identity is verified."
            />
          </div>
          <Alert
            variant="warning"
            title="Fractal Disclaimer"
            description="You are always in control of who has access to your information. Before we share your data with anyone, we will ask for your consent."
          >
            <a className={styles.alert_link} href="https://web.fractal.id/">
              <Flex align="center" gap="0.5rem">
                Go to Fractal Website
                <Icon size="1em" type="arrow-narrow-right" />
              </Flex>
            </a>
          </Alert>
        </div>
      </Page>
    </div>
  )
}

interface CardProps {
  step: string
  img: string
  title: string
  description: string
}

const Card = ({ step, img, title, description }: CardProps) => (
  <div className={styles.card}>
    <Badge className={styles.card_badge}>Step {step}</Badge>
    <div className={styles.card_img}>
      <img src={img} alt="Card" />
    </div>
    <h3 className={styles.card_title}>{title}</h3>
    <p>{description}</p>
  </div>
)
