// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_logo__1ED7m {\n  color: var(--th-clr-txt-primary);\n  vertical-align: middle;\n  --logo-clr-icon: var(--th-clr-primary);\n}", "",{"version":3,"sources":["webpack://src/apps/auth/components/Logo/Logo.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,sBAAA;EACA,sCAAA;AACF","sourcesContent":[".logo {\n  color: var(--th-clr-txt-primary);\n  vertical-align: middle;\n  --logo-clr-icon: var(--th-clr-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo_logo__1ED7m"
};
export default ___CSS_LOADER_EXPORT___;
