import React, { ReactNode } from 'react'
import {
  ConsentBanner,
  ConsentOptions,
  ConsentProvider,
} from 'react-hook-consent'

import { PrivacyPolicyLink } from 'src/components'

import { useRisksConsent } from '../RiskConsent'

import 'react-hook-consent/dist/styles/style.css'

const OPTIONS: ConsentOptions = {
  theme: 'dark',
  services: [],
}

export function GDPRConsent() {
  return (
    <ConsentBanner settings={{ hidden: true }} approve={{ label: 'Accept' }}>
      We want to use cookies and external services to analyze and improve this
      website for you. You will find more details in our{' '}
      <PrivacyPolicyLink>privacy policy</PrivacyPolicyLink>.
    </ConsentBanner>
  )
}

export function GDPRConsentProvider(props: { children: ReactNode }) {
  const { children } = props
  const risksAccepted = useRisksConsent()
  return (
    <ConsentProvider options={OPTIONS}>
      {children}

      {/* Don't display two modal banners at once, because it looks quite clunky.
      Risks banner has more priority, as it should block access to site completely */}
      <If condition={risksAccepted}>
        <GDPRConsent />
      </If>
    </ConsentProvider>
  )
}
