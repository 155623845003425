import { useState } from 'react'
import { toast } from 'react-toastify'

import { Link, Loader, Page } from 'src/components'

import { Logo } from '../../components/Logo/Logo'
import { fractalAuthUrl } from '../../config/fractal'
import { useSaveInviteCodeQuery } from '../../features/User'
import { routes } from '../../routes'

import styles from './OAuthStart.module.scss'

export function OAuthStartPage() {
  useSaveInviteCodeQuery()
  const [loading, setLoading] = useState(false)
  return (
    <div className={styles.root}>
      <Page
        className={styles.page}
        hero={<Logo className={styles.logo} />}
        title={
          <div style={{ textAlign: 'center' }}>Welcome to Polity Network</div>
        }
        description={
          <div style={{ textAlign: 'center' }}>
            The first enterprise-grade federated crypto wealth management
            network for professional advisors and their clients.
          </div>
        }
        alignHeader="center"
        alignContent="center"
      >
        <div className={styles.buttons}>
          <Link variant="btn-secondary" to={routes.fractal_info}>
            Learn more about Fractal
          </Link>

          <Link
            external
            endIcon={null}
            variant="btn-primary"
            to={fractalAuthUrl}
            onClick={() => {
              // redirect can take some noticeable time, so loader for it makes sense
              setLoading(true)
              showFractalIssuesHint()
            }}
          >
            <If condition={loading}>
              <Loader size="1em" color="currentColor" />
            </If>
            Continue to Fractal
          </Link>
        </div>
      </Page>
    </div>
  )
}

function showFractalIssuesHint() {
  if (process.env.REACT_APP_SHOW_FRACTAL_LOGIN_HELP !== '1') return

  toast.warn(
    <>
      <p>Dear Polity Client!</p>
      <p>
        If you are encountering any difficulties accessing your Polity account,
        please contact our Technical Support team:
      </p>
      <p>
        <a
          href="mailto:polity.support@quantumobile.com"
          style={{ color: 'var(--th-clr-primary)' }}
        >
          polity.support@quantumobile.com
        </a>
      </p>
    </>,
    {
      autoClose: false,
      style: {
        width: 360,
        // overflow toasts container, to get more width than usual
        position: 'absolute',
        right: 0,
      },
    }
  )
}
