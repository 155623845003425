import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Button, Modal } from 'src/components'
import { storageValue } from 'src/utils'

import styles from './styles.module.scss'

// ---

const Flag = storageValue('plt:risk-consent', false)

const useFlag = () => {
  const [state, setState] = useState(() => Flag.get())
  const setter = useCallback((x: typeof state) => {
    Flag.set(x)
    setState(x)
  }, [])
  return [state, setter] as const
}

const useRisksTimer = () => {
  const [isTimerReady, setIsTimerReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsTimerReady(true)
    }, 400)
  }, [])

  return isTimerReady
}

// ---

const RisksContext = createContext(Flag.get())
export const useRisksConsent = () => useContext(RisksContext)

// ---

export function RiskConsentProvider(props: { children: ReactNode }) {
  const { children } = props
  const [flag, setFlag] = useFlag()
  const isTimerReady = useRisksTimer()

  return (
    <RisksContext.Provider value={flag}>
      {children}
      {!flag && isTimerReady && (
        <Modal
          isOpen
          defaultRequired
          style={{ content: { maxWidth: 'min(80%, 592px)' } }}
          title="Disclaimer"
          footer={
            <RiskConsentFooter
              onAgreed={() => {
                setFlag(true)
              }}
            />
          }
        >
          <RiskConsentContent />
        </Modal>
      )}
    </RisksContext.Provider>
  )
}

const RiskConsentContent = () => (
  <div className={styles.root}>
    <p>
      Your access to and use of the Polity Private Minimum Viable Product
      (“Polity Private MVP”) by Bastion Cooperative (“Bastion”) is at your own
      risk. YOU UNDERSTAND AND AGREE THAT THE SERVICES OF THIS TRIAL RELEASE OF
      POLITY PRIVATE MVP (“SERVICES”) ARE PROVIDED TO YOU ON AN “AS IS” AND “AS
      AVAILABLE” BASIS. WITHOUT LIMITING THE FOREGOING, TO THE FULL EXTENT
      PERMITTED BY LAW, BASTION DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
    </p>
    <p>
      Bastion makes no representations or warranties of any kind with respect to
      the Services, including any representation or warranty that the use of the
      Services will (a) be timely, uninterrupted or error-free or operate in
      combination with any other software, hardware, system or data, (b) meet
      your requirements or expectations, (c) be free of viruses or other harmful
      components; (d) be entirely secure or that the information you share with
      us will be secure.
    </p>
    <p>
      POLITY PRIVATE MVP HAS NOT BEEN SECURITY AUDITED OR PENETRATION TESTED AND
      CAN BE VULNERABLE TO HACKING ATTACKS OR OTHER MALISCIOUS INTERFERENCE. The
      Services are not provided with respect to bank deposits, financial
      instruments, are not insured and are subject to risks, including possible
      loss of the entire principal amount of digital currencies you deposit to
      your wallet(s) at the Polity Private MVP. No advice or information,
      whether oral or written, obtained from Bastion through the Services, will
      create any warranty not expressly made herein. The Services may be
      unavailable in some geographic areas or to some individuals and
      organisations. Your eligibility for the Services is subject to the final
      determination by Bastion and/or its compliance service provider(s).
    </p>
    <p>
      TO THE FULL EXTENT PERMISSABLE BY LAW, BASTION, ITS OFFICERS, CONTRACTORS
      AND CONTRIBUTORS WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING
      FROM THE USE OF SERVICES, OR FROM ANY INFORMATION, CONTENT, MATERIALS,
      SOFTWARE OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AWAILABE TO YOU
      THROUGH POLITY PRIVATE MVP, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
      INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR
      OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR
      INABILITY TO ACCESS OR USE THE SERVICES; (B) ANY CONTENT OBTAINED FROM THE
      SERVICES; (C) UNAUTHORISED ACCESS, USE OR ALTERATION OF YOUR DATA,
      INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTIAL, PUNITIVE, AND
      CONSEQUENTIAL DEMAGES UNLESS OTHERWISE SPECIFIED IN WRITING.
    </p>
  </div>
)

const RiskConsentFooter = ({ onAgreed }: { onAgreed(): void }) => (
  <Button className={styles.btn_accept} onClick={onAgreed}>
    I understand and accept risks
  </Button>
)
