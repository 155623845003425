import React from 'react'

import { Grid } from 'src/components'
import { IJWTPair, UserRole } from 'src/types'

import { getCardByRole } from '../lib'
import { useRoleSelectFlow } from '../useRoleSelectFlow'

import styles from './RoleSelect.module.scss'

export function RoleSelect(props: IJWTPair) {
  const { access_token, refresh_token } = props
  const { onSelectRole, isLoggingIn, selectedRole, $verificator } =
    useRoleSelectFlow({ access_token, refresh_token })

  const AVAILABLE_ROLES = [
    UserRole.Investor,
    UserRole.Advisor,
    UserRole.Operator,
  ]

  return (
    <>
      {$verificator}

      <Grid className={styles.cards_list} gap={16}>
        {AVAILABLE_ROLES.map(role => {
          const Card = getCardByRole(role)
          if (Card === null) return null
          const selected = role === selectedRole
          return (
            <Card
              key={role}
              selected={selected}
              loading={selected && isLoggingIn}
              onClick={() => onSelectRole(role)}
            />
          )
        })}
      </Grid>
    </>
  )
}
