// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-7-1!../../../../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../../../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-7-3!../../../../../../../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-7-4!../../components/Navigation.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Intro_img_container__3ns95 {\n  border: var(--card-border);\n  border-radius: var(--card-border-radius);\n  background-color: var(--th-clr-bg-body);\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n}\n.Intro_img_container__3ns95 > img {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n.Intro_btn_continue__18voY {\n}\n\n.Intro_steps__11Q8n {\n  display: grid;\n  grid-gap: 40px;\n  gap: 40px;\n}", "",{"version":3,"sources":["webpack://src/apps/auth/features/Investor/components/NewAvatarWizard/steps/Intro/Intro.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,wCAAA;EACA,uCAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;;AAGA;AACA;;AAGA;EACE,aAAA;EACA,cAAA;EAAA,SAAA;AAAF","sourcesContent":[".img_container {\n  border: var(--card-border);\n  border-radius: var(--card-border-radius);\n  background-color: var(--th-clr-bg-body);\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n\n  > img {\n    max-width: 100%;\n    max-height: 100%;\n  }\n}\n\n.btn_continue {\n  composes: btn_continue from \"../../components/Navigation.module.scss\";\n}\n\n.steps {\n  display: grid;\n  gap: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img_container": "Intro_img_container__3ns95",
	"btn_continue": "Intro_btn_continue__18voY " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["btn_continue"] + "",
	"steps": "Intro_steps__11Q8n"
};
export default ___CSS_LOADER_EXPORT___;
