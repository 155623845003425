import { Button, Link } from 'src/components'

import { IWizardStepRendererProps } from '../../types'

import { Step } from './Step'
import imgMain from './img-main.png'
import imgStep1 from './img-step-1.png'
import imgStep2 from './img-step-2.png'

import styles from './Intro.module.scss'

export function StepIntro(
  props: { onNext?(): void } & IWizardStepRendererProps
) {
  const { onNext, LayoutComponent } = props
  return (
    <LayoutComponent
      title="Install & Setup Element"
      subtitle="Element offers a secure and compliant communication solution, empowering you with data sovereignty through open-source and decentralized hosting."
      footer={
        <Button className={styles.btn_continue} onClick={onNext}>
          Continue
        </Button>
      }
      aside={
        <div className={styles.img_container}>
          <img src={imgMain} alt="img-main" />
        </div>
      }
      style={{
        ['--card-aside-width' as string]: 'auto',
      }}
    >
      <Steps />
    </LayoutComponent>
  )
}

function Steps() {
  return (
    <div className={styles.steps}>
      <Step
        label="Step 1"
        title="Create your Element Account"
        subtitle="Visit Element’s website and create your free account in a matter of minutes."
        img={<img src={imgStep1} alt="img-step-1" />}
      >
        <Link
          to="https://app.element.io/"
          variant="btn-primary"
          external
          endIcon={null}
        >
          Go to Element
        </Link>

        <Link
          to="https://element.io/"
          variant="primary"
          external
          endIcon={null}
        >
          Learn more about Element.io
        </Link>
      </Step>

      <Step
        label="Step 2"
        title="Copy your Element ID Address"
        subtitle="Once you get setup with Element, a unique Element ID address will be created. Keep it handy as you will need it for the following step."
        img={<img src={imgStep2} alt="img-step-2" />}
      >
        <Link
          to="https://element.io/user-guide"
          variant="primary"
          external
          endIcon={null}
        >
          Read Element user guide
        </Link>
      </Step>
    </div>
  )
}
