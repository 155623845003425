import { extendLogger } from 'src/log'
import { storageValue } from 'src/utils'

import { ELEMENT_APP_HOST, MATRIX_DOMAIN } from './const'
import { TRoomAlias, TUserID } from './types'

export const Log = extendLogger('element.io')

export const composeRoomURL = (alias: TRoomAlias) =>
  `https://${ELEMENT_APP_HOST}/#/room/${alias}`

export const RE_USER_ID = /^@.+:\w+\.\w+$/
export const normalizeUserID = (
  nickOrID: string | TUserID,
  domain: string = MATRIX_DOMAIN
): TUserID =>
  RE_USER_ID.test(nickOrID) ? (nickOrID as TUserID) : `@${nickOrID}:${domain}`

// TODO: check proper format in docs; maybe it's not only about spaces
export const normalizeRoomAlias = (x: string) => x.replaceAll(' ', '_')

export const matrixAccessToken = storageValue('matrix-access-token')
