// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormRow_row__1W-K1 {\n  min-height: 160px;\n  display: grid;\n  align-items: center;\n}\n.FormRow_row__1W-K1.FormRow_compact__n_GGd {\n  min-height: 96px;\n}", "",{"version":3,"sources":["webpack://src/apps/auth/features/Investor/components/NewAvatarWizard/components/FormRow.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".row {\n  min-height: 160px;\n  display: grid;\n  align-items: center;\n\n  &.compact {\n    min-height: 96px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "FormRow_row__1W-K1",
	"compact": "FormRow_compact__n_GGd"
};
export default ___CSS_LOADER_EXPORT___;
