import { toast } from 'react-toastify'

import { useAsync, useAsyncUnwrap } from 'src/hooks'
import { IJWTPair, UserRole } from 'src/types'

import { login } from '../../features/User'
import { useDispatch } from '../../store'

import { AdvisorCard, InvestorCard, OperatorCard } from './components/Cards'

export function getCardByRole(role: UserRole) {
  switch (role) {
    case UserRole.Investor:
      return InvestorCard
    case UserRole.Advisor:
      return AdvisorCard
    case UserRole.Operator:
      return OperatorCard
    default:
      return null
  }
}

export function useLoginHandler(tokens: IJWTPair) {
  const dispatch = useDispatch()
  return useAsyncUnwrap(
    useAsync((role: UserRole) =>
      dispatch(login({ ...tokens, role }))
        .unwrap()
        .catch(e => {
          const msg = (e as Error).message
          toast.error(msg, {
            toastId: 'login',
            autoClose: false,
          })
          return Promise.reject(e)
        })
    )
  )
}

export function isRoleNeedVerification(role: UserRole) {
  return role === UserRole.Advisor || role === UserRole.Operator
}
