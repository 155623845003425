import { ReactNode, useContext } from 'react'

import clsx from 'clsx'

import { WizardContext } from '../WizardContext'

import styles from './FormRow.module.scss'

export function FormRow(props: { children: ReactNode }) {
  const { children } = props

  const { wizardMode } = useContext(WizardContext)

  return (
    <div
      className={clsx(styles.row, {
        [styles.compact]: wizardMode === 'compact',
      })}
    >
      {children}
    </div>
  )
}
