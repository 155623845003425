// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_root__jLCRV {\n  display: flex;\n  flex-wrap: wrap;\n  min-height: 100vh;\n}\n\n.Login_page__2g8B_ {\n  flex-basis: 50%;\n  flex-grow: 1;\n  min-width: 480px;\n  padding: 48px 64px;\n}\n\n.Login_logo__2SNOr {\n  margin-bottom: 78px;\n}", "",{"version":3,"sources":["webpack://src/apps/auth/pages/Login/Login.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".root {\n  display: flex;\n  flex-wrap: wrap;\n  min-height: 100vh;\n}\n\n.page {\n  flex-basis: 50%;\n  flex-grow: 1;\n  min-width: 480px;\n  padding: 48px 64px;\n}\n\n.logo {\n  margin-bottom: 78px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Login_root__jLCRV",
	"page": "Login_page__2g8B_",
	"logo": "Login_logo__2SNOr"
};
export default ___CSS_LOADER_EXPORT___;
