import axios, { AxiosError } from 'axios'
import { merge } from 'lodash'

import {
  API_BASE_PATH,
  MATRIX_SERVER,
  SYSTEM_USER_ID,
  SYSTEM_USER_PWD,
} from './const'
import {
  Log,
  composeRoomURL,
  matrixAccessToken,
  normalizeRoomAlias,
} from './lib'
import {
  IMatrixLoginResponse,
  IMatrixRoomResponse,
  TRoomAlias,
  TRoomID,
} from './types'

const api = axios.create({
  baseURL: `https://${MATRIX_SERVER}/${API_BASE_PATH}`,
})

const LOGIN_URL = 'login'

api.interceptors.request.use(async request => {
  if (request.url === LOGIN_URL) return request

  let token = matrixAccessToken.get()
  if (token === undefined) {
    const {
      data: { access_token },
    } = await login()

    matrixAccessToken.set(access_token)
    token = access_token
  }

  merge(request, {
    params: { access_token: token },
  })

  return request
})

api.interceptors.response.use(undefined, (axiosError: AxiosError) => {
  Log.error(axiosError)
  const message = axiosError.response?.data?.error
  if (message) {
    return Promise.reject(message)
  }
  return Promise.reject(axiosError)
})

export const login = () =>
  api.post<IMatrixLoginResponse>(LOGIN_URL, {
    type: 'm.login.password',
    identifier: {
      type: 'm.id.user',
      user: SYSTEM_USER_ID,
    },
    password: SYSTEM_USER_PWD,
  })

/**
 * @link https://spec.matrix.org/v1.8/client-server-api/#mroomencryption
 */
const encryptRoom = ({ room_id }: { room_id: TRoomID }) =>
  api.put<void>(
    `rooms/${encodeURIComponent(room_id)}/state/m.room.encryption`,
    {
      algorithm: 'm.megolm.v1.aes-sha2',
    }
  )

/**
 * @see https://spec.matrix.org/latest/client-server-api/#creation
 */
export const createRoom = async ({
  alias,
  invite,
}: {
  alias: string
  invite?: string[]
}) => {
  const name = normalizeRoomAlias(alias)
  const response = await api.post<IMatrixRoomResponse>('createRoom', {
    name,
    room_alias_name: name,
    invite,
  })
  const { room_id } = response.data
  await encryptRoom({ room_id })
  const [fullAlias] = await getRoomAliases({ id: room_id })
  return {
    ...response.data,
    room_alias: fullAlias,
  }
}

/**
 * @see https://spec.matrix.org/latest/client-server-api/#get_matrixclientv3roomsroomidaliases
 */
export const getRoomAliases = async ({
  id,
}: {
  id: TRoomID
}): Promise<TRoomAlias[]> => {
  const {
    data: { aliases },
  } = await api.get<{ aliases: TRoomAlias[] }>(`rooms/${id}/aliases`)
  return aliases
}

export const getRoomURL = async ({ id }: { id: TRoomID }): Promise<string> => {
  const [alias] = await getRoomAliases({ id })
  if (alias === undefined) {
    throw new Error(
      `Room ${id} doesn't have any aliases. Can't build a room URL without alias.`
    )
  }

  return composeRoomURL(alias)
}
