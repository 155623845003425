import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import {
  TypedUseSelectorHook,
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from 'react-redux'

import { rtqApi } from 'src/api'
import { HistoryReferrerSlice } from 'src/features/HistoryReferrer'
import { useUser } from 'src/features/User'

import { InvestorApi, InvestorSlice } from './features/Investor'
import { UserSlice } from './features/User'
import { history } from './routes'

export const rootReducer = combineReducers({
  [rtqApi.reducerPath]: rtqApi.reducer,
  [InvestorApi.reducerPath]: InvestorApi.reducer,
  router: connectRouter(history),
  referrer: HistoryReferrerSlice.reducer,
  user: UserSlice.reducer,
  investor: InvestorSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaults =>
    getDefaults().concat(
      routerMiddleware(history),
      rtqApi.middleware,
      InvestorApi.middleware
    ),
})

setupListeners(store.dispatch)

// ---

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof rootReducer>
export type AppSelector<T = unknown> = (state: AppState) => T
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>

export const useSelector: TypedUseSelectorHook<AppState> = useSelectorBase
export const useDispatch: () => AppDispatch = useDispatchBase
export { useUser }
