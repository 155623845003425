import { Link, Page } from 'src/components'

import { AuthLayout } from '../../components'
import { routes } from '../../routes'

import logoImg from './logoSuccess.png'

import styles from './OAuthSuccess.module.scss'

export function OAuthSuccessPage() {
  return (
    <AuthLayout>
      <Page
        className={styles.page}
        hero={<img className={styles.logo} src={logoImg} alt="Logo" />}
        title={<div style={{ textAlign: 'center' }}>Successful Whitelist</div>}
        description={
          <div style={{ textAlign: 'center' }}>
            Congratulations, you have been successfully whitelisted and now have
            network access. Continue to the onboarding stage displayed on the
            next screen.
          </div>
        }
        alignHeader="center"
        alignContent="center"
      >
        <Link
          variant="btn-primary"
          className={styles.button}
          // Pass through all query params. Expected to have auth tokens there.
          to={`${routes.login}${window.location.search}`}
        >
          Continue
        </Link>
      </Page>
    </AuthLayout>
  )
}
