import React from 'react'
import { Redirect } from 'react-router-dom'

import qs from 'qs'

import { routes } from 'src/apps/auth'
import { ErrorMessage, Loader, Page } from 'src/components'
import { useAsyncEffect, useQueryParams } from 'src/hooks'

import { AuthLayout } from '../components'
import { InviteCode, UserAPI } from '../features/User'

export function OAuthCallbackPage() {
  const { code } = useQueryParams<{ code: string }>()

  if (code === undefined) {
    return <Redirect to={routes.home} />
  }

  return (
    <AuthLayout>
      <Page>
        <ExchangeScreen code={code} />
      </Page>
    </AuthLayout>
  )
}

function ExchangeScreen(props: { code: string }) {
  const { code } = props

  const {
    status,
    error,
    value: tokens,
  } = useAsyncEffect(() => UserAPI.exchangeFractalCodeToToken(code), [code])

  const isNewUser = InviteCode.get() !== undefined

  return (
    <Choose>
      <When condition={status === 'error'}>
        <ErrorMessage>{error}</ErrorMessage>
      </When>

      <When condition={status === 'loading'}>
        <Loader />
      </When>

      <When condition={status === 'success'}>
        <Redirect
          to={`${
            isNewUser ? routes.fractal_success : routes.login
          }?${qs.stringify(tokens)}`}
        />
      </When>
    </Choose>
  )
}
