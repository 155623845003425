import { createAxiosInstance } from 'src/api/api'
import { IJWTPair } from 'src/types'

import {
  IAuthParams,
  IAuthResponse,
  ILoginParams,
  ISignupParams,
} from './types'

const FractalAPI = createAxiosInstance({
  baseURL: 'fractal-auth',
})

async function login(params: ILoginParams) {
  const { data } = await FractalAPI.post<IAuthResponse>('login', params)
  return data
}

async function signup(params: ISignupParams): Promise<IAuthResponse> {
  const { access_token, refresh_token, role, code } = params
  try {
    const response = await FractalAPI.post<IAuthResponse>('registration', {
      token: access_token,
      refresh_token,
      role,
      code: +code, // api requires a number
    })
    return response.data
  } catch (e) {
    const { message } = e as Error
    const msgUserExists = /Username .+? already taken/.exec(message)?.[0]
    if (msgUserExists !== undefined) {
      throw new Error(msgUserExists)
    } else {
      throw e
    }
  }
}

export async function authenticate(
  params: IAuthParams
): Promise<IAuthResponse> {
  const { code, role, access_token, refresh_token } = params
  // By design: it is assumed that user only submits role code
  // when he first time comes to site by invitation link.
  const isNewUser = code !== undefined

  if (isNewUser) {
    return signup({ code, role, access_token, refresh_token })
  }

  return login({ role, token: access_token })
}

/**
 * @param {string} code The `code` query param provided to oauth callback url
 */
export async function exchangeFractalCodeToToken(code: string) {
  const { data } = await FractalAPI.post<IJWTPair>('obtain-token', undefined, {
    params: { code },
  })
  return data
}

export async function verifySecurityCode(code: string): Promise<boolean> {
  // TODO
  await new Promise(res => setTimeout(res, 2000))
  const isValid = code === '291697'

  if (!isValid) {
    throw new Error('Invalid security code')
  }
  return true
}
