import { Navigation } from '../../components'
import { IStepAvatarData, IStepContainerProps } from '../../types'

import { AvatarForm } from './Form'

const FORM_ID = 'wizard-avatar-form'

export function StepAvatarForm(props: IStepContainerProps<IStepAvatarData>) {
  const {
    onDataReceived,
    onBack,
    onNext,
    defaults,
    showBtnBack,
    showBtnNext,
    LayoutComponent,
  } = props
  return (
    <LayoutComponent
      title="Set up your avatar"
      subtitle="An Avatar serves as your unique digital identity on the network. Opt for a unique name and photo that aligns with your personal brand."
      footer={
        <Navigation targetFormId={FORM_ID} {...{ showBtnBack, showBtnNext }} />
      }
    >
      <AvatarForm
        id={FORM_ID}
        defaults={defaults}
        onSubmit={data => {
          onDataReceived?.(data)
          onNext?.()
        }}
        onReset={data => {
          onDataReceived?.(data)
          onBack?.()
        }}
      />
    </LayoutComponent>
  )
}
