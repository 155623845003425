import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { AuthRoute, GuestRoute, UserRole } from 'src/features/User'

import { InvestorRouter } from './features/Investor'
import { FractalInfoPage } from './pages/FractalInfo'
import { LoginPage } from './pages/Login'
import { OAuthCallbackPage } from './pages/OAuthCallback'
import { OAuthStartPage } from './pages/OAuthStart'
import { OAuthSuccessPage } from './pages/OAuthSuccess'
import { routes } from './routes'
import { useSelector } from './store'

export default function AppRouter() {
  const role = useSelector(state => state.user.data.role)
  return (
    <Switch>
      <AuthRoute roles={[UserRole.Investor]} path={routes.investor.root}>
        <InvestorRouter />
      </AuthRoute>

      <If condition={role === UserRole.Investor}>
        <Redirect to={routes.investor.dashboard} />
      </If>

      <GuestRoute path={routes.home} component={OAuthStartPage} exact />
      <GuestRoute path={routes.oauth_cb} component={OAuthCallbackPage} />
      <GuestRoute path={routes.login} component={LoginPage} />
      <GuestRoute path={routes.fractal_info} component={FractalInfoPage} />
      <GuestRoute path={routes.fractal_success} component={OAuthSuccessPage} />
    </Switch>
  )
}
