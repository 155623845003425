import { createAsyncThunk } from '@reduxjs/toolkit'

import api from 'src/api'

import { IAuthResponse, loginToInfraApp } from '../User'

import * as ElementAPI from './ElementAPI'
import { IInvestorAvatar } from './types'

export const loginAvatar = createAsyncThunk(
  'investor/login_avatar',
  async (id: IInvestorAvatar['id'], { dispatch }) => {
    const { data } = await api.post<IAuthResponse>(
      'investor/avatars/login',
      {
        id,
      },
      {
        validateStatus: x => x === 302,
      }
    )
    dispatch(loginToInfraApp(data))
  }
)

export const inviteAdvisor = createAsyncThunk(
  'investor/inviteAdvisor',
  async (payload: {
    advisor: { id: number; name: string; element_id: string }
    avatar: { id: number; name: string; element_id: string }
  }) => {
    const { advisor, avatar } = payload
    try {
      const { room_id, room_alias } = await ElementAPI.createRoom({
        alias: `${avatar.name}__${advisor.name}__${Date.now()}`,
        invite: [
          ElementAPI.normalizeUserID(
            advisor.element_id,
            // TODO: this is probably going to change in future stages; for PoC@1.0 stage only
            ElementAPI.DEFAULT_MATRIX_DOMAIN
          ),
          ElementAPI.normalizeUserID(
            avatar.element_id,
            /* By customer's request:
             * Assume that avatars are arbitrary users on platform –
             * so they use their personal accounts on public element.io servers.
             * While advisors are platform participants (like "employees"), and have accounts on corporate server.
             * TODO: this is going to change after PoC1.0; waiting for new requirements from customer */
            ElementAPI.DEFAULT_MATRIX_DOMAIN
          ),
        ],
      })

      /* TODO:
          check whether it is possible for Admin to leave the room after it's created.
          So that avatar and advisor remain in private there.
          @see https://spec.matrix.org/latest/client-server-api/#post_matrixclientv3roomsroomidleave */

      // @link https://dcspoc.atlassian.net/browse/PLT-421
      // Notify our backend that Element invite has been sent
      await api.post('investor', {
        avatarId: avatar.id,
        adviserId: advisor.id,
      })

      return {
        room_id,
        room_link: ElementAPI.composeRoomURL(room_alias),
      }
    } catch (e) {
      ElementAPI.Log.error('[InviteAdvisor]', e)
      throw e
    }
  }
)
