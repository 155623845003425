import { ReactNode } from 'react'

import { WizardProgressbar } from 'src/components/domain'
import { UserRole } from 'src/types'
import { getUserRoleLabel, zipObjectFromKeys } from 'src/utils'

import { WizardStep } from '../types'
import { DEFAULT_STEPS_ORDER } from '../useWizard'

export function StepsProgress(props: {
  step: WizardStep
  order: WizardStep[]
}) {
  const { step, order = DEFAULT_STEPS_ORDER } = props
  return (
    <WizardProgressbar
      step={step}
      steps={zipObjectFromKeys(order, getStepLabel)}
    />
  )
}

function getStepLabel(step: WizardStep): ReactNode {
  switch (step) {
    case WizardStep.Intro:
      return 'Setup Element.io'
    case WizardStep.Avatar:
      return 'Set up your avatar'
    case WizardStep.Advisor:
      return (
        <>
          Connect to a<br />
          {getUserRoleLabel(UserRole.Advisor)}
        </>
      )
  }
}
