import React from 'react'

import { UserRole } from 'src/types'
import { getUserRoleLabel } from 'src/utils'

import { Card } from '../Card'
import { ICardProps } from '../types'

import { ReactComponent as IconAdvisor } from './images/advisor.svg'
import { ReactComponent as IconInvestor } from './images/investor.svg'
import { ReactComponent as IconOperator } from './images/operator.svg'

type RoleCardProps = Omit<ICardProps, 'title' | 'image' | 'children'>

export function InvestorCard(props: RoleCardProps) {
  return (
    <Card
      {...props}
      title={getUserRoleLabel(UserRole.Investor)}
      icon={<IconInvestor />}
    >
      HNWIs, privately-owned companies or small family offices who use the
      Polity Network for self-advised, advised and/or managed crypto portfolios
    </Card>
  )
}

export function AdvisorCard(props: RoleCardProps) {
  return (
    <Card
      {...props}
      title={getUserRoleLabel(UserRole.Advisor)}
      icon={<IconAdvisor />}
    >
      Professional crypto advisors (individuals or companies) servicing
      Investors by providing virtual private nodes and technical support as well
      as qualified crypto advice
    </Card>
  )
}

export function OperatorCard(props: RoleCardProps) {
  return (
    <Card
      {...props}
      title={getUserRoleLabel(UserRole.Operator)}
      icon={<IconOperator />}
    >
      Individuals and/or companies responsible for the development and
      maintenance of the Polity Network and technical support of the Advisors
    </Card>
  )
}
