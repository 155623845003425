import { List } from 'src/components'
import { DEFAULT_POLLING_INTERVAL } from 'src/const'

import { InvestorApi } from '../../api'
import { AvatarItem } from '../AvatarItem'

import styles from './styles.module.scss'

export function AvatarsList() {
  // Avatars status will update when adviser creates a node acquisition contract
  const { data = [], isLoading } = InvestorApi.endpoints.getAvatars.useQuery(
    undefined,
    {
      pollingInterval: DEFAULT_POLLING_INTERVAL,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    }
  )

  return (
    <List
      className={styles.root}
      data={data}
      loading={isLoading}
      renderItem={item => (
        <AvatarItem
          id={item.id}
          name={item.name}
          photo={item.photo ?? undefined}
          inactive={!item.is_active}
        />
      )}
      empty="You have no avatars yet"
    />
  )
}
