import { ReactNode, useContext } from 'react'

import clsx from 'clsx'

import { WizardContext } from '../WizardContext'

import styles from './FieldLabel.module.scss'

export function FieldLabel(props: { children: ReactNode }) {
  const { children } = props

  const { wizardMode } = useContext(WizardContext)

  return (
    <div
      className={clsx(styles.title, {
        [styles.title_md]: wizardMode === 'extend',
        [styles.title_sm]: wizardMode === 'compact',
      })}
    >
      {children}
    </div>
  )
}
