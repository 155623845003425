import { push } from 'connected-react-router'
import { Redirect } from 'react-router-dom'

import { routes, useDispatch } from 'src/apps/auth'
import { useQueryParams } from 'src/hooks'

import { WizardSuccessScreen } from '../../components/NewAvatarWizard'

import { IAvatarCreatedPageQueryParams } from './types'

import styles from './AvatarCreatedPage.module.scss'

export function AvatarCreatedPage() {
  const { name, room, img } = useQueryParams<IAvatarCreatedPageQueryParams>()
  const dispatch = useDispatch()

  if (!(name !== undefined && room !== undefined && img !== undefined)) {
    return <Redirect to={routes.investor.dashboard} />
  }

  return (
    <div className={styles.container}>
      <WizardSuccessScreen
        advisorName={name}
        advisorImg={img}
        roomId={room}
        onOpenElement={() => {
          dispatch(push(routes.investor.dashboard))
        }}
      />
    </div>
  )
}
