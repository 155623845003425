// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navigation_root__2J0xt {\n  display: flex;\n  justify-content: flex-end;\n  grid-gap: 1em;\n  gap: 1em;\n}\n\n.Navigation_btn_continue__2AqCu {\n  min-width: 111px;\n}\n\n.Navigation_btn_back__uYcnn {\n  --btn-clr-txt: var(--th-clr-gray-700);\n}", "",{"version":3,"sources":["webpack://src/apps/auth/features/Investor/components/NewAvatarWizard/components/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,aAAA;EAAA,QAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,qCAAA;AACF","sourcesContent":[".root {\n  display: flex;\n  justify-content: flex-end;\n  gap: 1em;\n}\n\n.btn_continue {\n  min-width: 111px;\n}\n\n.btn_back {\n  --btn-clr-txt: var(--th-clr-gray-700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Navigation_root__2J0xt",
	"btn_continue": "Navigation_btn_continue__2AqCu",
	"btn_back": "Navigation_btn_back__uYcnn"
};
export default ___CSS_LOADER_EXPORT___;
