import React from 'react'
import { Redirect } from 'react-router-dom'

import { Page } from 'src/components'
import { useQueryParams } from 'src/hooks'
import { IJWTPair } from 'src/types'

import { Logo } from '../../components/Logo/Logo'
import { useSaveInviteCodeQuery } from '../../features/User'
import { routes } from '../../routes'

import { LoginAside } from './components/Aside'
import { RoleSelect } from './components/RoleSelect'

import styles from './Login.module.scss'

export function LoginPage() {
  useSaveInviteCodeQuery()

  const { access_token, refresh_token } = useQueryParams<IJWTPair>()

  if (access_token === undefined || refresh_token === undefined) {
    console.warn(
      "Didn't found valid pair of JWT tokens in query params. Redirecting to home page."
    )
    return <Redirect to={routes.home} />
  }

  return (
    <div className={styles.root}>
      <Page
        className={styles.page}
        hero={<Logo className={styles.logo} />}
        title="Portal Login"
        description="Please select your role"
      >
        <RoleSelect access_token={access_token} refresh_token={refresh_token} />
      </Page>
      <LoginAside />
    </div>
  )
}
