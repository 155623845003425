import { createContext } from 'react'

import { IWizardContext } from './types'

export const WizardContext = createContext<IWizardContext>({
  wizardMode: 'extend',
  submit: {
    loading: false,
    error: undefined,
  },
})
