import { ReactNode, useContext } from 'react'

import { ControlSettings, Grid } from 'src/components'

import { WizardContext } from '../WizardContext'

export function WizardFormLayout(props: { children: ReactNode }) {
  const { children } = props
  const { wizardMode } = useContext(WizardContext)
  switch (wizardMode) {
    case 'extend': {
      return (
        <ControlSettings
          layout="repeat(2, 1fr)"
          gap={40}
          alignInput="center"
          hintPosition="label"
        >
          {children}
        </ControlSettings>
      )
    }

    case 'compact': {
      return (
        <Grid gap="0.5rem">
          <ControlSettings layout="col" hintPosition="footer">
            {children}
          </ControlSettings>
        </Grid>
      )
    }

    default:
      return null
  }
}
