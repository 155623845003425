import clsx from 'clsx'

import { Link } from 'src/components'
import { ReactComponent as LogoImg } from 'src/logo-polity.svg'

import { routes } from '../../routes'

import styles from './Logo.module.scss'

interface ILogoProps {
  className?: string
  href?: string
}

export function Logo(props: ILogoProps) {
  const { className, href = routes.home } = props

  return (
    <Link to={href}>
      <LogoImg className={clsx(styles.logo, className)} />
    </Link>
  )
}
