// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-7-1!../../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-7-3!../../../../../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-7-4!../AvatarItem/AvatarItem.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__15bld {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["item-size"] + ");\n  min-height: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["item-size"] + ";\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://src/apps/auth/features/Investor/components/AvatarsList/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,qFAAA;EACA,wDAAA;EACA,uBAAA;AAAF","sourcesContent":["@value item-size from \"../AvatarItem/AvatarItem.module.scss\";\n\n.root {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, item-size);\n  min-height: item-size;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item-size": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["item-size"] + "",
	"root": "styles_root__15bld"
};
export default ___CSS_LOADER_EXPORT___;
