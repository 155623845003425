export const ELEMENT_APP_HOST =
  process.env.REACT_APP_ELEMENTIO_HOST || 'app.element.io'

export const DEFAULT_MATRIX_DOMAIN = 'matrix.org'
export const MATRIX_DOMAIN =
  process.env.REACT_APP_MATRIX_DOMAIN || DEFAULT_MATRIX_DOMAIN
export const MATRIX_SERVER =
  process.env.REACT_APP_MATRIX_SERVER || MATRIX_DOMAIN
export const SYSTEM_USER_ID = process.env.REACT_APP_MATRIX_USER
export const SYSTEM_USER_PWD = process.env.REACT_APP_MATRIX_PWD

export const API_BASE_PATH = '_matrix/client/r0'
