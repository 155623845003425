import { FieldPath } from 'react-hook-form'

import { FieldInput, IFieldInputProps } from 'src/components'
import { validators } from 'src/validation'

import { InvestorApi } from '../../../../api'

export function FieldAvatarName<
  Fields extends { name: string },
  Name extends FieldPath<Fields>
>(
  props: { name: Name } & Pick<
    IFieldInputProps,
    'label' | 'placeholder' | 'hint'
  >
) {
  return (
    <FieldInput
      {...props}
      rules={{
        ...validators.rulesRequiredString,
        validate: {
          uniq: useUniqNameValidator(),
        },
      }}
    />
  )
}

function useUniqNameValidator() {
  const { names } = InvestorApi.endpoints.getAvatars.useQuery(undefined, {
    selectFromResult: ({ data }) => ({ names: data?.map(x => x.name) ?? [] }),
  })

  return (x: string) => {
    if (names.includes(x)) {
      return 'This name is already taken'
    }
  }
}
