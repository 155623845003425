import { FieldInput, Form } from 'src/components'

import { RE_USER_ID as RE_ELEMENT_IO_ID } from '../../../../ElementAPI'
import { FieldLabel, FormRow, WizardFormLayout } from '../../components'
import { IStepAvatarData } from '../../types'

import { FieldAvatarName } from './FieldAvatarName'
import { FieldAvatarPhoto } from './FieldAvatarPhoto'

interface IStepFormProps<T> {
  id: string
  onSubmit?(data: T): void
  onReset?(data: T): void
  defaults?: T
}

export function AvatarForm(props: IStepFormProps<IStepAvatarData>) {
  const { id, defaults, onSubmit, onReset } = props

  return (
    <Form<IStepAvatarData>
      showDefaultControls={false}
      id={id}
      defaultValues={defaults}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <WizardFormLayout>
        <FormRow>
          <FieldAvatarPhoto<IStepAvatarData, 'photo'>
            name="photo"
            label={<FieldLabel>Upload your Avatar photo</FieldLabel>}
          />
        </FormRow>

        <FormRow>
          <FieldAvatarName<IStepAvatarData, 'name'>
            name="name"
            label={<FieldLabel>Avatar Name</FieldLabel>}
            placeholder="Type Avatar Name here..."
            hint="An Avatar name is unique to your Investor profile only."
          />
        </FormRow>

        <FormRow>
          <FieldInput<IStepAvatarData, 'element_id'>
            name="element_id"
            rules={{
              required: true,
              pattern: {
                value: RE_ELEMENT_IO_ID,
                message: 'Invalid format',
              },
            }}
            placeholder="@username:matrix.org"
            label={<FieldLabel>Element ID Address</FieldLabel>}
            hint="Please insert the address found in your Element account."
          />
        </FormRow>
      </WizardFormLayout>
    </Form>
  )
}
