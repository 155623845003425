import { useCallback, useState } from 'react'
import OtpInput from 'react-otp-input'

import { Icon, Loader } from 'src/components'
import { useAsync } from 'src/hooks'

import styles from './VerificationForm.module.scss'

export interface IVerificationFormProps {
  onSubmit(code: string): void
}

const NUM_INPUTS = 6

export function VerificationForm(props: IVerificationFormProps) {
  const { onSubmit } = props

  const [{ status }, sendCode] = useAsync(async (code: string) => {
    await onSubmit(code)
  })
  const isLoading = status === 'loading'
  const isFinished = status === 'success' || status === 'error'
  const isCodeValid = status === 'success'

  const [verificationCode, setVerificationCode] = useState('')

  const onCodeChange = useCallback((code: string) => {
    setVerificationCode(code)
    if (code.length === NUM_INPUTS) {
      sendCode(code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <OtpInput
          value={verificationCode}
          onChange={onCodeChange}
          isInputNum
          containerStyle={styles.input_container}
          inputStyle={styles.input}
          numInputs={NUM_INPUTS}
          shouldAutoFocus
          isInputSecure
          // presence of non-empty-string placeholder allows to do
          // some fancy input styling with special pseudo-classes
          placeholder={' '.repeat(NUM_INPUTS)}
        />

        <If condition={isLoading}>
          <Loader className={styles.spinner} />
        </If>

        <If condition={isFinished}>
          <Icon
            size={28}
            className={styles.icon}
            type={isCodeValid ? 'success' : 'error'}
            color
          />
        </If>
      </div>
    </div>
  )
}
