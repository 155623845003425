import React from 'react'
import { Redirect } from 'react-router-dom'

import { routes } from 'src/apps/auth'
import { Card, Grid, Page as PageLayout, RTQSuspender } from 'src/components'

import { InvestorApi } from '../../api'
import { AvatarsList } from '../../components'

import { BtnAddAvatar } from './BtnAddAvatar'

export const InvestorDashboardPage: React.FC = () => {
  const avatarsQuery = InvestorApi.endpoints.getAvatars.useQuery()

  return (
    <RTQSuspender state={avatarsQuery}>
      {avatars => (
        <Choose>
          <When condition={avatars.length === 0}>
            <Redirect to={routes.investor.getting_started} />
          </When>

          <Otherwise>
            <PageContent />
          </Otherwise>
        </Choose>
      )}
    </RTQSuspender>
  )
}

function PageContent() {
  return (
    <PageLayout
      pushdown
      alignHeader="center"
      title="Investor Portal"
      description="Click on your Avatar to enter your Avatar Dashboard."
    >
      <Card>
        <Grid gap="3.5rem">
          <AvatarsList />
          <BtnAddAvatar />
        </Grid>
      </Card>
    </PageLayout>
  )
}
