import { UserRole } from 'src/types'
import { getUserRoleLabel } from 'src/utils'

import { Navigation } from '../../components'
import { IStepAdvisorData, IStepContainerProps } from '../../types'

import { AdvisorForm } from './Form'

const FORM_ID = 'wizard-nsp-form'

export function StepAdvisorForm(props: IStepContainerProps<IStepAdvisorData>) {
  const {
    onDataReceived,
    onBack,
    onNext,
    defaults,
    showBtnBack,
    showBtnNext,
    LayoutComponent,
  } = props
  return (
    <LayoutComponent
      title={<>Connect to a {getUserRoleLabel(UserRole.Advisor)}</>}
      subtitle={
        <>
          Your {getUserRoleLabel(UserRole.Advisor)} will be your key link on the
          network, providing professional and regulated crypto market guidance.
        </>
      }
      footer={
        <Navigation targetFormId={FORM_ID} {...{ showBtnBack, showBtnNext }} />
      }
    >
      <AdvisorForm
        id={FORM_ID}
        defaults={defaults}
        onSubmit={data => {
          onDataReceived?.(data)
          onNext?.()
        }}
        onReset={data => {
          onDataReceived?.(data)
          onBack?.()
        }}
      />
    </LayoutComponent>
  )
}
