import {
  FC,
  Fragment,
  ReactNode,
  createContext,
  createElement,
  useContext,
  useDebugValue,
  useMemo,
} from 'react'
import { createPortal } from 'react-dom'

import { uniqueId } from 'lodash'

import { useIsMounted } from 'src/hooks'

interface ILayoutSlotsIds {
  header: string
  menu: string
}

const DEFAULT_IDS: ILayoutSlotsIds = {
  header: 'auth-layout-slot-header',
  menu: 'auth-layout-slot-menu',
}

export const SlotsContext = createContext<ILayoutSlotsIds>({
  header: 'auth-layout-slot-header',
  menu: 'auth-layout-slot-menu',
})

// TODO: upgrade to react@18
function useId(prefix?: string) {
  return useMemo(() => uniqueId(prefix), [prefix])
}

export function useSlotIds(): ILayoutSlotsIds {
  return {
    header: useId(DEFAULT_IDS.header),
    menu: useId(DEFAULT_IDS.menu),
  }
}

function createPortalRenderer(name: keyof ILayoutSlotsIds): FC<{
  children: ReactNode
  key?: string
}> {
  return function PortalRenderer(props) {
    useDebugValue(`PortalRenderer-${name}`)
    const ids = useContext(SlotsContext)
    const id = ids[name]
    const isMounted = useIsMounted()

    // Re-query element after mount, as at render phase it may not exist yet.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const root = useMemo(() => document.getElementById(id), [id, isMounted])

    const { children, key } = props
    return root
      ? createElement(Fragment, undefined, createPortal(children, root, key))
      : null
  }
}

export const slots = {
  header: createPortalRenderer('header'),
  menu: createPortalRenderer('menu'),
}
