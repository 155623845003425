import { ReactNode } from 'react'

import { routes, useDispatch } from 'src/apps/auth'
import { logout } from 'src/apps/auth/features/User'
import { BtnLogout } from 'src/components'

import { AuthLayout } from '../../../components'

export function InvestorLayout(props: { children: ReactNode }) {
  const { children } = props
  const dispatch = useDispatch()
  return (
    <AuthLayout
      hrefHome={routes.investor.dashboard}
      slotMenu={<BtnLogout onClick={() => dispatch(logout())} />}
    >
      {children}
    </AuthLayout>
  )
}
