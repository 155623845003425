import React from 'react'

import styles from './Aside.module.scss'

export const LoginAside = () => (
  <aside className={styles.root}>
    <div className={styles.content}>
      <div className={styles.quote}>
        <p>
          The right kind of democracy, if you will, is a polity: An ideal
          democracy that governs for the interests of all, not just the
          leadership.
        </p>
        <p>-Aristotle</p>
      </div>
      <div className={styles.ui_preview} />
    </div>
  </aside>
)
