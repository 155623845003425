import { useContext } from 'react'

import { Button, Loader } from 'src/components'

import { WizardContext } from '../WizardContext'

import styles from './Navigation.module.scss'

export interface IWizardNavigationProps {
  targetFormId: string

  onBack?(): void
  onNext?(): void

  showBtnBack?: boolean
  showBtnNext?: boolean
}

export function Navigation(props: IWizardNavigationProps) {
  const {
    targetFormId,
    onBack,
    onNext,
    showBtnNext = true,
    showBtnBack = true,
  } = props

  const {
    submit: { loading: isSubmitting },
  } = useContext(WizardContext)

  return (
    <div className={styles.root}>
      {showBtnBack && (
        <Button
          className={styles.btn_back}
          variant="secondary"
          onClick={onBack}
          type="reset"
          form={targetFormId}
          disabled={isSubmitting}
        >
          Back
        </Button>
      )}

      {showBtnNext && (
        <Button
          className={styles.btn_continue}
          variant="primary"
          onClick={onNext}
          type="submit"
          form={targetFormId}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Loader size="1em" color="currentColor" />
          ) : (
            'Continue'
          )}
        </Button>
      )}
    </div>
  )
}
