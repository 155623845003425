import { ReactElement } from 'react'

import clsx from 'clsx'

import { routes } from 'src/apps/auth/routes'
import { Button, ErrorBlock, Link, Loader } from 'src/components'
import { UserRole } from 'src/types'
import { getUserRoleLabel } from 'src/utils'

import { TRoomID } from '../../../../ElementAPI'
import { RoomLinkProvider } from '../RoomLinkProvider'

import { ReactComponent as Banner } from './banner.svg'

import styles from './SuccessScreen.module.scss'

type LayoutRenderer = (params: {
  img: ReactElement
  header: ReactElement
  footer: ReactElement
}) => ReactElement

const renderDefault: LayoutRenderer = ({ img, header, footer }) => (
  <>
    {img}
    {header}
    {footer}
  </>
)

interface IWizardSuccessScreenProps {
  advisorName: string
  advisorImg: string | null
  roomId: string
  onOpenElement?(): void
  onGoToDashboard?(): void
  render?: LayoutRenderer
  mode?: 'compact'
}

export function WizardSuccessScreen(props: IWizardSuccessScreenProps) {
  const {
    advisorName,
    advisorImg,
    roomId,
    onOpenElement,
    onGoToDashboard,
    mode,
    render = renderDefault,
  } = props
  return (
    <div
      className={clsx(styles.root, {
        [styles.compact]: mode === 'compact',
      })}
    >
      {render({
        img: (
          <div className={styles.banner_holder}>
            <Banner width={320} height={320} />
            <If condition={advisorImg !== null}>
              <img
                id="nsp-photo"
                src={advisorImg as NonNullable<typeof advisorImg>}
                alt={advisorName}
                className={styles.advisor_photo}
              />
            </If>
          </div>
        ),

        header: (
          <div className={styles.header}>
            <h2 className={styles.title}>
              Start your conversation with {advisorName} on Element
            </h2>

            <h3 className={styles.subtitle}>
              Before gaining access to the platform,
              {getUserRoleLabel(UserRole.Advisor)}'s approval is required. Your
              Avatar’s status will be shown as ‘Connected’ and your access will
              be granted.
            </h3>
          </div>
        ),

        footer: (
          <div className={styles.footer}>
            <RoomLinkProvider
              room_id={roomId as TRoomID}
              renderLoading={() => (
                <Button
                  className={styles.btn_room}
                  disabled
                  variant="secondary"
                >
                  <Loader size="1rem" />
                </Button>
              )}
              renderError={() => (
                <ErrorBlock className={styles.btn_room}>
                  <span style={{ fontWeight: 500 }}>
                    Failed to get room link
                  </span>
                </ErrorBlock>
              )}
            >
              {link => (
                <Link
                  to={link}
                  variant="btn-secondary"
                  external
                  endIcon={null}
                  className={styles.btn_room}
                  onClick={onOpenElement}
                >
                  Open Element
                </Link>
              )}
            </RoomLinkProvider>

            <Link
              to={routes.investor.dashboard}
              variant="btn-primary"
              className={styles.btn_dashboard}
              onClick={onGoToDashboard}
            >
              Go to Dashboard
            </Link>
          </div>
        ),
      })}
    </div>
  )
}
