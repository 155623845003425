import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { GUEST_USER_ID, UserRole } from 'src/features/User'
import { addAsyncThunkReducers } from 'src/utils'

import * as thunks from './thunks'
import { IUser, IUserState } from './types'

const GuestUser: IUser = {
  id: GUEST_USER_ID,
  role: UserRole.Test,
  username: 'Guest',
  photo: null,
}

const initialState: IUserState = {
  loading: false,
  error: undefined,
  data: GuestUser,
}

export const UserSlice = createSlice({
  name: 'user',

  initialState,

  reducers: {
    setUser(state, { payload }: PayloadAction<IUser>) {
      state.data = payload
    },
  },

  extraReducers: builder => {
    addAsyncThunkReducers(builder, thunks.checkInvestorSession, 'data')
    addAsyncThunkReducers(builder, thunks.logout, state => {
      state.data = GuestUser
    })

    // addAsyncThunkReducers(builder, thunks.startLoginProcess, 'authResponse')
    addAsyncThunkReducers(builder, thunks.loginInvestor, (state, user) => {
      state.data = user
    })
  },
})
