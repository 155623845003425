import { useEffect } from 'react'

import { useQueryParams } from 'src/hooks'
import { storageValue } from 'src/utils'

/**
 * It is not in redux store, because this value must persist across redirect to Fractal.id site.
 *
 * Maybe it can be achieved if we install redux-persist plugin.
 * But basically direct usage of localStorage is enough.
 */
export const InviteCode = storageValue('plt:invite_code')

export function useSaveInviteCodeQuery() {
  const { code } = useQueryParams<{ code: string }>()
  useEffect(() => {
    if (code !== undefined && code !== '') {
      InviteCode.set(code)
    }
  }, [code])
}
