import { CSSProperties, FC, ReactNode } from 'react'

import {
  ICreateAvatarFormFields,
  IInvestorAdvisor,
  IInvestorAvatar,
} from '../../types'

import { IWizardNavigationProps } from './components'

export enum WizardStep {
  Intro = 'intro',
  Avatar = 'avatar',
  Advisor = 'advisor',
}

export interface IWizardProps {
  step?: WizardStep
  skipIntro?: boolean
  showProgressbar?: boolean
  onStepChange?(step: WizardStep): void
  onInitialized?(step: WizardStep): void
  onFinish?(): void
  onComplete?(data: IWizardResult): void
  mode?: WizardMode
  StepLayoutComponent?: WizardStepLayoutComponent
}

export type WizardStepLayoutComponent = FC<{
  title: ReactNode
  subtitle: ReactNode
  footer: ReactNode
  children: ReactNode
  aside?: ReactNode
  style?: CSSProperties
}>

export interface IWizardStepRendererProps {
  LayoutComponent: WizardStepLayoutComponent
}

export interface IStepContainerProps<T>
  extends Omit<IWizardNavigationProps, 'targetFormId'>,
    IWizardStepRendererProps {
  onDataReceived?(form: T): void
  defaults?: T
}

export interface IUseWizardParams
  extends Pick<
    IWizardProps,
    'step' | 'skipIntro' | 'onStepChange' | 'onComplete'
  > {}

export interface IStepAvatarData extends ICreateAvatarFormFields {}
export interface IStepAdvisorData {
  advisor: IInvestorAdvisor
}

export interface IWizardState {
  stepAvatar?: IStepAvatarData
  stepAdvisor?: IStepAdvisorData
}

type WizardMode = 'extend' | 'compact'

export interface IWizardContext {
  wizardMode: WizardMode
  submit: {
    loading: boolean
    error: Error | undefined
  }
}

export interface IWizardResult {
  avatar: IInvestorAvatar
  advisor: IInvestorAdvisor
  room_id: string
  room_link: string
}
