import { Page } from 'src/components'
import { UserRole } from 'src/types'
import { getUserRoleLabel } from 'src/utils'

import { WizardContainer } from './WizardContainer'

export function OnboardingPage() {
  return (
    <Page
      title="Getting started"
      description={
        <>
          Let's get you setup with a secure communication channel, your own
          personalized avatar and syncing with a{' '}
          {getUserRoleLabel(UserRole.Advisor)}.
        </>
      }
    >
      {/* @link https://dcspoc.atlassian.net/browse/PLT-582
       * When form is stretched to fullscreen width on large screens, it becomes barely unreadable.
        Design doesn't take this into account, so width is chosen by personal taste. */}
      <div style={{ maxWidth: 1024 }}>
        <WizardContainer />
      </div>
    </Page>
  )
}
