// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OAuthSuccess_page__1bKem {\n  max-width: 800px;\n  margin: 48px auto;\n}\n\n.OAuthSuccess_logo__1TzJu {\n  display: block;\n  margin: 0 auto 16px;\n}\n\n.OAuthSuccess_button__3ktb5 {\n  margin-top: -8px;\n}", "",{"version":3,"sources":["webpack://src/apps/auth/pages/OAuthSuccess/OAuthSuccess.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".page {\n  max-width: 800px;\n  margin: 48px auto;\n}\n\n.logo {\n  display: block;\n  margin: 0 auto 16px;\n}\n\n.button {\n  margin-top: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "OAuthSuccess_page__1bKem",
	"logo": "OAuthSuccess_logo__1TzJu",
	"button": "OAuthSuccess_button__3ktb5"
};
export default ___CSS_LOADER_EXPORT___;
