import { ReactNode } from 'react'

import { Badge } from 'src/components'

import styles from './Step.module.scss'

export function Step(props: {
  img: ReactNode
  label: ReactNode
  title: ReactNode
  subtitle: ReactNode
  children?: ReactNode
}) {
  const { img, label, children, title, subtitle } = props
  return (
    <div className={styles.step}>
      <Badge size="sm">{label}</Badge>
      <div className={styles.main}>
        <div className={styles.img}>{img}</div>
        <div className={styles.content}>
          <div className={styles.header}>
            <h5 className={styles.title}>{title}</h5>
            <h6 className={styles.subtitle}>{subtitle}</h6>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
