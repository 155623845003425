import { ReactElement } from 'react'
import { FormatOptionLabelMeta } from 'react-select/base'

import { FieldSelect, Flex, Form, Icon } from 'src/components'
import { useDebounce } from 'src/hooks'
import { UserRole } from 'src/types'
import { getUserRoleLabel } from 'src/utils'

import { InvestorApi } from '../../../../api'
import { IInvestorAdvisor } from '../../../../types'
import { FieldLabel, FormRow, WizardFormLayout } from '../../components'
import { IStepAdvisorData } from '../../types'

interface IStepFormProps<T> {
  id: string
  onSubmit?(data: T): void
  onReset?(data: T): void
  defaults?: T
}

export function AdvisorForm(props: IStepFormProps<IStepAdvisorData>) {
  const { id, defaults, onSubmit, onReset } = props
  const search = useSearchHandler()
  return (
    <Form
      showDefaultControls={false}
      id={id}
      onSubmit={onSubmit}
      onReset={onReset}
      defaultValues={defaults}
    >
      <WizardFormLayout>
        <FormRow>
          <FieldSelect
            name="advisor"
            rules={{
              required: true,
            }}
            placeholder={<>Select a {getUserRoleLabel(UserRole.Advisor)}</>}
            label={
              <FieldLabel>
                Select a {getUserRoleLabel(UserRole.Advisor)}
              </FieldLabel>
            }
            async
            autoFocus
            loadOptions={search}
            defaultOptions
            cacheOptions
            optionAsValue
            getOptionValue={x => x.id}
            getOptionLabel={x => x.name}
            formatOptionLabel={renderOption}
          />
        </FormRow>
      </WizardFormLayout>
    </Form>
  )
}

function renderOption(
  option: IInvestorAdvisor,
  meta: FormatOptionLabelMeta<unknown>
): ReactElement {
  return (
    <Flex align="center" justify="space-between">
      <Flex align="center" gap="0.5rem">
        <If condition={option.photo !== null}>
          <img
            src={option.photo as NonNullable<typeof option.photo>}
            alt={option.name}
            style={{ width: 24, aspectRatio: '1', borderRadius: '50%' }}
          />
        </If>
        {option.name}
      </Flex>
      <If
        condition={meta.context === 'menu' && meta.selectValue.includes(option)}
      >
        <Icon size={20} type="check" color />
      </If>
    </Flex>
  )
}

function useSearchHandler() {
  const [search] = InvestorApi.endpoints.getAdvisers.useLazyQuery()
  return useDebounce(
    // don't use `async` version, being debounced it doesn't work with react-select
    (q: string, resolve: (xs: IInvestorAdvisor[]) => void) => {
      search({ q }).unwrap().then(resolve)
    },
    500
  )
}
