import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  IAuthTokens,
  getAccessToken,
  getRefreshToken,
  setAuthTokens,
} from 'axios-jwt'

import {
  ERROR_NO_CREDENTIALS_FOUND,
  UserRole,
  UserThunks,
  decodeUserData,
} from 'src/features/User'
import { Log } from 'src/log'
import { IJWTPair } from 'src/types'
import { normalizeUrl } from 'src/utils'

import * as API from './api'
import { InviteCode } from './lib'
import { IAuthResponse, IBaseAuthParams } from './types'

export const login = createAsyncThunk(
  'user/login',
  async (data: IBaseAuthParams, { dispatch }) => {
    const getTokens = () => {
      try {
        const code = InviteCode.get()
        return API.authenticate({ ...data, code })
      } finally {
        /* Regardless of success of fail, remove stored code, so it will never be used again.
         * So user who failed to sign up in one browser and did it in another one,
         * can then come back to first browser and log in there. */
        InviteCode.remove()
      }
    }

    try {
      const response = await getTokens()
      const jwt = response.access_token
      const user = decodeUserData(jwt)
      if (user.role === UserRole.Investor) {
        return dispatch(loginInvestor(response))
      } else {
        return dispatch(loginToInfraApp(response))
      }
    } catch (e) {
      Log.error('Failed to auth user', e)
      throw e
    }
  }
)

export const loginToInfraApp = createAsyncThunk(
  'user/loginToInfraApp',
  (data: IAuthResponse) => {
    const tokensState: IAuthTokens = {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
    }

    const redirectTarget = new URL(normalizeUrl(data.redirect_url))
    for (const [k, v] of Object.entries(tokensState)) {
      redirectTarget.searchParams.append(k, v)
    }

    window.location.assign(redirectTarget.toString())
  }
)

export const loginInvestor = createAsyncThunk(
  'user/loginInvestor',
  (jwt: IJWTPair) => {
    // Save tokens to be used by axios-jwt middleware
    setAuthTokens({
      accessToken: jwt.access_token,
      refreshToken: jwt.refresh_token,
    })

    const user = decodeUserData(jwt.access_token)
    if (user.role !== UserRole.Investor) {
      throw new Error(
        `Invalid Investor JWT token: encoded user role is ${user.role}`
      )
    }
    return user
  }
)

export const logout = UserThunks.logout

/**
 * The handler which tries to restore existing "session" on app load
 */
export const checkInvestorSession = createAsyncThunk(
  'user/checkInvestorSession',
  (_, { dispatch }) => {
    const access_token = getAccessToken()
    const refresh_token = getRefreshToken()

    if (!(access_token && refresh_token)) {
      throw new Error(ERROR_NO_CREDENTIALS_FOUND)
    }

    const action = loginInvestor({ access_token, refresh_token })
    const user = dispatch(action).unwrap()
    return user
  }
)
