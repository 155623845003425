// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__3b2om p {\n  margin-bottom: 1rem;\n}\n\n.styles_btn_accept__mTmX4 {\n  width: 100%;\n  justify-self: center;\n}", "",{"version":3,"sources":["webpack://src/apps/auth/components/RiskConsent/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,oBAAA;AACF","sourcesContent":[".root p {\n  margin-bottom: 1rem;\n}\n\n.btn_accept {\n  width: 100%;\n  justify-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__3b2om",
	"btn_accept": "styles_btn_accept__mTmX4"
};
export default ___CSS_LOADER_EXPORT___;
