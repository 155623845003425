// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./grid-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OAuthStart_root__2Ykkh {\n  display: flex;\n  align-items: center;\n  height: 100vh;\n  overflow: hidden;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n}\n\n.OAuthStart_page__2NFbm {\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.OAuthStart_logo__VwcGk {\n  display: block;\n  width: auto;\n  height: 60px;\n  margin-bottom: 38px;\n}\n\n.OAuthStart_buttons__WqbWd {\n  display: flex;\n  justify-content: center;\n  grid-gap: 12px;\n  gap: 12px;\n}", "",{"version":3,"sources":["webpack://src/apps/auth/pages/OAuthStart/OAuthStart.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,2EAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EAAA,SAAA;AACF","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  height: 100vh;\n  overflow: hidden;\n  background: url(\"./grid-bg.png\") no-repeat center center;\n}\n\n.page {\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.logo {\n  display: block;\n  width: auto;\n  height: 60px;\n  margin-bottom: 38px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OAuthStart_root__2Ykkh",
	"page": "OAuthStart_page__2NFbm",
	"logo": "OAuthStart_logo__VwcGk",
	"buttons": "OAuthStart_buttons__WqbWd"
};
export default ___CSS_LOADER_EXPORT___;
