import { ReactNode } from 'react'

import { routes } from 'src/apps/auth'
import { useChanged } from 'src/hooks'

import { Logo } from '../Logo/Logo'

import { SlotsContext, slots, useSlotIds } from './slots'

import styles from './Layout.module.scss'

export interface IAuthLayoutProps {
  children: ReactNode
  slotMenu?: ReactNode
  slotHeader?: ReactNode
  hrefHome?: string
}

// TODO:
//  same layout (with kinda different paddings) is used in Cabinet app
//  consider moving it into `src/components/layouts` and dedupe
export function AuthLayout(props: IAuthLayoutProps) {
  const { children, slotMenu, slotHeader, hrefHome = routes.home } = props

  const slotIds = useSlotIds()
  return (
    <SlotsContext.Provider value={useChanged(slotIds)}>
      <div className={styles.root}>
        <header className={styles.header}>
          <Logo href={hrefHome} />

          <div className={styles.slot_header} id={slotIds.header}>
            {slotHeader}
          </div>

          <div className={styles.slot_menu} id={slotIds.menu}>
            {slotMenu}
          </div>
        </header>

        <main className={styles.content}>{children}</main>
      </div>
    </SlotsContext.Provider>
  )
}

export const AuthLayoutSlotHeader = slots.header
export const AuthLayoutSlotMenu = slots.menu
