import qs from 'qs'

import { normalizeUrl } from 'src/utils'

import { HISTORY_BASENAME, routes } from '../routes'

/**
 * @see https://docs.developer.fractal.id/kyc-levels
 * @see https://docs.developer.fractal.id/oauth-2.0-scopes
 */

const {
  REACT_APP_FRACTAL_AUTH_DOMAIN: AUTH_DOMAIN,
  REACT_APP_FRACTAL_CLIENT_ID: CLIENT_ID,
} = process.env

const FRACTAL_PARAMS = {
  client_id: CLIENT_ID,
  response_type: 'code',
  redirect_uri: normalizeUrl(
    `${window.location.origin}/${HISTORY_BASENAME}/${routes.oauth_cb}`
  ),
  scope: [
    'uid:read',
    'verification.basic:read',
    'verification.basic.details:read',
    'verification.liveness:read',
    'verification.liveness.details:read',
  ].join(' '),
}

export const fractalAuthUrl = `${AUTH_DOMAIN}/authorize?${qs.stringify(
  FRACTAL_PARAMS
)}`
