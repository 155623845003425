import { createBrowserHistory } from 'history'

export const routes = {
  home: '/',
  login: '/login',
  fractal_info: '/fractal_info',
  fractal_success: '/fractal_success',

  oauth_in: '/oauth/start',
  oauth_cb: '/oauth/callback',

  investor: {
    root: '/investor',
    get dashboard() {
      return this.root
    },
    get getting_started() {
      return `${this.dashboard}/getting-started` as const
    },
    get avatar_created() {
      return `${this.dashboard}/avatar-created` as const
    },
  },
} as const

// ---

export const HISTORY_BASENAME = '/'

export const history = createBrowserHistory({
  basename: HISTORY_BASENAME,
})
