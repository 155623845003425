import clsx from 'clsx'

import { Loader } from 'src/components'

import { ReactComponent as Arrow } from './arrow.svg'
import { ICardProps } from './types'

import styles from './Card.module.scss'

export function Card(props: ICardProps) {
  const {
    tag: Tag = 'div',
    title,
    children: description,
    icon,
    selected = false,
    loading = false,
    className,
    ...rest
  } = props

  return (
    <Tag
      {...rest}
      className={clsx(styles.card, className, styles.selectable, {
        [styles.selected]: selected,
        [styles.loading]: loading,
      })}
    >
      <div className={styles.icon_holder}>
        <div className={styles.icon_slot}>{icon}</div>
        <Loader className={styles.spinner} size={36} />
      </div>

      <div className={styles.main}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
      <Arrow className={styles.arrow} />
    </Tag>
  )
}
