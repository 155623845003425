import { toast } from 'react-toastify'

import { AuthLayoutSlotHeader } from 'src/apps/auth/components'
import { Card } from 'src/components'
import { useOnChange, useOnMount } from 'src/hooks'

import { WizardContext } from './WizardContext'
import { StepsProgress } from './components'
import { StepAdvisorForm } from './steps/Advisor'
import { StepAvatarForm } from './steps/Avatar'
import { StepIntro } from './steps/Intro'
import { IWizardProps, WizardStep } from './types'
import { getStepsOrder, useWizard } from './useWizard'

export function NewAvatarWizard(props: IWizardProps) {
  const {
    showProgressbar = true,
    skipIntro = false,
    mode = 'extend',
    onInitialized,
    StepLayoutComponent = Card,
  } = props
  const { step, state, setState, onBack, onNext, submitState } =
    useWizard(props)

  useOnMount(() => {
    // notify about which step is really active,
    // accordingly to internal steps validation logic
    onInitialized?.(step)
  })

  useOnChange(submitState.error, error => {
    if (error !== undefined) {
      toast.error(error.message, {
        toastId: 'wizard-complete',
        autoClose: false,
      })
    }
  })

  return (
    <WizardContext.Provider value={{ submit: submitState, wizardMode: mode }}>
      <If condition={showProgressbar}>
        <AuthLayoutSlotHeader>
          <StepsProgress step={step} order={getStepsOrder(skipIntro)} />
        </AuthLayoutSlotHeader>
      </If>

      <Choose>
        <When condition={step === WizardStep.Intro}>
          <StepIntro onNext={onNext} LayoutComponent={StepLayoutComponent} />
        </When>

        <When condition={step === WizardStep.Avatar}>
          <StepAvatarForm
            defaults={state.stepAvatar}
            onDataReceived={data => {
              setState(state => {
                state.stepAvatar = data
              })
            }}
            onBack={onBack}
            onNext={onNext}
            showBtnBack={!skipIntro}
            LayoutComponent={StepLayoutComponent}
          />
        </When>

        <When condition={step === WizardStep.Advisor}>
          <StepAdvisorForm
            defaults={state.stepAdvisor}
            onDataReceived={data => {
              setState(state => {
                state.stepAdvisor = data
              })
            }}
            onBack={onBack}
            onNext={onNext}
            LayoutComponent={StepLayoutComponent}
          />
        </When>
      </Choose>
    </WizardContext.Provider>
  )
}
