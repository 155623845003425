import { createApi as createRTQApi } from '@reduxjs/toolkit/query/react'

import { map } from 'lodash/fp'

import { axiosBaseQuery, rtqTags } from 'src/api'
import { toFormData } from 'src/utils'

import {
  ICreateAvatarFormFields,
  IEditAvatarFormFields,
  IInvestorAdvisor,
  IInvestorAvatar,
  IInvestorAvatarRaw,
} from './types'

const tags = rtqTags(['Avatar', 'Advisor'])

// ---

export const InvestorApi = createRTQApi({
  reducerPath: 'investor_api',
  baseQuery: axiosBaseQuery({
    baseURL: 'investor',
  }),
  tagTypes: tags.toArray(),
  endpoints: builder => ({
    getAvatars: builder.query<IInvestorAvatar[], void>({
      query: () => 'avatars',
      providesTags: tags.Avatar.providesList,
      transformResponse: map(parseAvatar),
    }),

    getAvatar: builder.query<IInvestorAvatar, IInvestorAvatar['id']>({
      query: id => `avatars/${id}`,
      providesTags: tags.Avatar.providesOne,
      transformResponse: parseAvatar,
    }),

    createAvatar: builder.mutation<IInvestorAvatar, ICreateAvatarFormFields>({
      query(data) {
        return {
          url: 'avatars',
          method: 'POST',
          data: toFormData({
            ...data,
            name: data.name.trim(),
          }),
        }
      },
      invalidatesTags: [tags.Avatar.list()],
      transformResponse: parseAvatar,
    }),

    getAdvisers: builder.query<IInvestorAdvisor[], { q: string } | void>({
      query: opts => `advisers?q=${opts?.q}`,
      providesTags: tags.Advisor.providesList,
    }),

    updateAvatar: builder.mutation<IInvestorAvatar, IEditAvatarFormFields>({
      query({ id, name }) {
        return {
          url: `avatars/${id}`,
          method: 'PATCH',
          data: { name: name.trim() },
        }
      },
      invalidatesTags: (result, error, { id }) => [
        tags.Avatar(id),
        tags.Avatar.list(),
      ],
      transformResponse: parseAvatar,
    }),
  }),
})

function parseAvatar(x: IInvestorAvatarRaw): IInvestorAvatar {
  return {
    id: x.id,
    photo: x.photo,
    name: x.username,
    is_active: x.hasAcquiredNode,
    element_id: x.element_id,
  }
}
