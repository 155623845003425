// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldLabel_title__1isFc {\n  font-weight: 500;\n}\n.FieldLabel_title__1isFc.FieldLabel_title_md__3Opyc {\n  font-size: 20px;\n  line-height: 1.5;\n  color: var(--th-clr-txt-primary);\n}\n.FieldLabel_title__1isFc.FieldLabel_title_sm__3O_wD {\n  font-size: 14px;\n  line-height: 1.43;\n  color: var(--th-clr-txt-secondary);\n}", "",{"version":3,"sources":["webpack://src/apps/auth/features/Investor/components/NewAvatarWizard/components/FieldLabel.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,gCAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,kCAAA;AAAJ","sourcesContent":[".title {\n  font-weight: 500;\n\n  &.title_md {\n    font-size: 20px;\n    line-height: 1.5;\n    color: var(--th-clr-txt-primary);\n  }\n\n  &.title_sm {\n    font-size: 14px;\n    line-height: 1.43;\n    color: var(--th-clr-txt-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "FieldLabel_title__1isFc",
	"title_md": "FieldLabel_title_md__3Opyc",
	"title_sm": "FieldLabel_title_sm__3O_wD"
};
export default ___CSS_LOADER_EXPORT___;
