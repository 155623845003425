import { useContext } from 'react'
import {
  Controller as FieldController,
  FieldPath,
  FieldValues,
} from 'react-hook-form'

import { FieldFileInput, IFieldFileInputProps } from 'src/components'
import { ObjectURLProvider } from 'src/hooks'

import { WizardContext } from '../../WizardContext'

import { ReactComponent as IconUploader } from './icon-uploader.svg'

import styles from './Uploader.module.scss'

export function FieldAvatarPhoto<
  Fields extends FieldValues = FieldValues,
  Name extends FieldPath<Fields> = FieldPath<Fields>
>(props: Pick<IFieldFileInputProps<Fields, Name>, 'name' | 'label'>) {
  const { name, label } = props

  const { wizardMode } = useContext(WizardContext)

  return (
    <FieldFileInput<Fields, Name>
      name={name}
      unstyled
      accept="image/*"
      rules={{
        required: true,
        maxSize: 1024 * 1024,
      }}
      label={label}
      className={styles.uploader}
      style={{
        justifySelf: wizardMode === 'extend' ? 'end' : 'start',
      }}
      control={{
        alignLabel: 'center',
        ...(wizardMode === 'extend' && {
          classNameError: styles.error_msg_extend,
          collapseEmptyFooter: true,
        }),
      }}
      placeholder={
        <div className={styles.uploader_placeholder}>
          <div className={styles.uploader_icon_holder}>
            <IconUploader className={styles.uploader_icon} />
          </div>
          <div>
            <div className={styles.uploader_text_primary}>Click to upload</div>
            <div className={styles.uploader_text_secondary}>
              or drag and drop
            </div>
          </div>
        </div>
      }
      renderFiles={({ open }) => (
        /* Use form's Controller to access field value rather than own react-dropzone's value (provided to `renderFiles`),
         * because react-dropzone RESETS ITS VALUE whenever file dialog opens:
         * @see https://github.com/react-dropzone/react-dropzone/blob/c36ab5bd8b8fd74e2074290d80e3ecb93d26b014/src/index.js#L995-L999
         * You need an external storage (which form's state is) if you want to keep current value in case "open file dialog and then cancel it". */
        <FieldController<Fields, Name>
          name={name}
          render={({ field: { value: file } }) => (
            <If condition={file !== undefined}>
              <div onClick={open} className={styles.uploader_file_preview}>
                <ObjectURLProvider obj={file}>
                  {src => <img src={src} alt={file.name} />}
                </ObjectURLProvider>
              </div>
            </If>
          )}
        />
      )}
    />
  )
}
