import { Route, Switch } from 'react-router-dom'

import { routes } from 'src/apps/auth'

import { InvestorLayout } from './components'
import { AvatarCreatedPage } from './pages/AvatarCreated'
import { InvestorDashboardPage } from './pages/Dashboard'
import { OnboardingPage } from './pages/NewAvatar'

export function InvestorRouter() {
  return (
    <InvestorLayout>
      <Switch>
        <Route
          path={routes.investor.dashboard}
          component={InvestorDashboardPage}
          exact
        />

        <Route
          path={routes.investor.getting_started}
          component={OnboardingPage}
        />

        <Route
          path={routes.investor.avatar_created}
          component={AvatarCreatedPage}
        />
      </Switch>
    </InvestorLayout>
  )
}
