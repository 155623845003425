import { useAsyncEffect } from '@react-hook/async'
import { ReactElement, useEffect } from 'react'
import { toast } from 'react-toastify'

import { TRoomID, getRoomURL } from '../../../ElementAPI'

export function RoomLinkProvider(props: {
  room_id: TRoomID
  children: (link: string) => ReactElement
  renderError?: (error: Error) => ReactElement
  renderLoading?: () => ReactElement
}) {
  const { room_id, children, renderError, renderLoading } = props
  const { status, value, error } = useAsyncEffect(
    () => getRoomURL({ id: room_id }),
    [room_id]
  )

  useEffect(() => {
    if (error !== undefined) {
      toast.error(error.message, {
        autoClose: false,
        toastId: 'get-room-url',
      })
    }
  }, [error])

  switch (status) {
    case 'loading':
      return renderLoading?.() ?? null

    case 'error':
      return renderError?.(error as NonNullable<typeof error>) ?? null

    case 'success':
      return children(value as string)

    default:
      return null
  }
}
