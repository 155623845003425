import { Badge, Tooltip } from 'src/components'
import { UserRole } from 'src/types'
import { getUserRoleLabel } from 'src/utils'

export function AvatarConnectionStatus(props: { pending: boolean }) {
  const { pending } = props
  return (
    <Badge variant={pending ? 'warn' : 'success'} icon size="sm">
      <Choose>
        <When condition={pending}>
          <Tooltip placement="bottom" overlay={renderPendingStatusTooltip()}>
            <span>Pending</span>
          </Tooltip>
        </When>

        <Otherwise>Connected</Otherwise>
      </Choose>
    </Badge>
  )
}

function renderPendingStatusTooltip() {
  return (
    <div style={{ maxWidth: 300, fontSize: '0.75rem' }}>
      <div>
        You should conclude a{' '}
        <b style={{ color: 'var(--th-clr-primary)' }}>
          Node Acquisition Agreement
        </b>{' '}
        with your {getUserRoleLabel(UserRole.Advisor)} to start using this
        avatar.
      </div>
      <br />
      <div>
        {/* TODO:
          It would be nice to display an actual element.io id of current avatar.
          Should find out where it's stored on backend */}
        Check out the Element.io account you've linked to this avatar – you
        should receive an invite to a room, where you can discuss agreement
        details with a {getUserRoleLabel(UserRole.Advisor)}.
      </div>
    </div>
  )
}
